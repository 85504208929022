.circliful .outer {
    fill: transparent;
    stroke: #333;
    stroke-width: 19.8;
    stroke-dasharray: 534;
    transition: stroke-dashoffset 1s;
    -webkit-animation-play-state: running;
    /* firefox bug fix - won't rotate at 90deg angles */
    -moz-transform: rotate(-89deg) translateX(-190px);
}

/* full circle 25 empty 534 */
.circliful .inner {
    fill: transparent;
    stroke: orange;
    stroke-width: 20;
    stroke-dasharray: 534;
    transition: stroke-dashoffset 1s;
    -webkit-animation-play-state: running;
    /* firefox bug fix - won't rotate at 90deg angles */
    -moz-transform: rotate(-89deg) translateX(-190px);
    stroke-dashoffset: 0;
}

.circliful {
    overflow: visible !important;

}

.svg-container {
    width: 100%;
    margin: 0 auto;
    overflow: visible;
}

svg .icon {
    font-family: FontAwesome;
}
